import inView from 'in-view';
const scroll = require('scroll');
const page = require('scroll-doc')();
const ease = require('ease-component');

require('midnight.js');
$(document).ready(function() {
  $('.header-bg').midnight();
});

inView('.section')

$('.section-control').on('click', function() {
  if (!$(this).hasClass('section-control--up')) {
    const currentSection = inView('.section').check().current[0];
    const nextSection = $(currentSection).next();

    if ($(nextSection).length) {
      const offsetTop = $(nextSection).offset().top;
      scroll.top(page, offsetTop, { duration: 500 });
    } else {
      const bottom = $('body').innerHeight() - $(window).innerHeight();
      scroll.top(page, bottom, { duration: 500 });
      // $('.section-control').addClass('section-control--up');
    }
  } else {
    scroll.top(page, 0, { duration: 1000 });
    // $('.section-control').removeClass('section-control--up');
  }
});

$('.header-mobile-toggle').on('click', function() {
  $(this).toggleClass('is-active');

  $('.header__mobile').fadeToggle(300);
})

function arrowPos() {
  const bodyHeight = $('body').innerHeight();
  const scrollTop = $(window).scrollTop();
  const winHeight = $(window).innerHeight();

  if (scrollTop + winHeight >= bodyHeight) {
    $('.section-control').addClass('section-control--up');
  } else {
    $('.section-control').removeClass('section-control--up');
  }
}

arrowPos();

$(window).on('scroll', arrowPos);
