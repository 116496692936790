import Swiper from 'swiper/dist/js/swiper'

const swiperProducts = new Swiper('.b-products-slider', {
  loop: true,
  speed: 1000,
  parallax: true,
  threshold: 10,
  navigation: {
    nextEl: '.b-products-slider .swiper-button-next',
    prevEl: '.b-products-slider .swiper-button-prev'
  },
});

const swiperAdvantages = new Swiper('.b-advantages-slider', {
  loop: true,
  speed: 1000,
  parallax: true,
  threshold: 10,
  navigation: {
    nextEl: '.b-advantages-slider .swiper-button-next',
    prevEl: '.b-advantages-slider .swiper-button-prev'
  },
});

const swiperClients = new Swiper('.clients-slider .swiper-container', {
  loop: true,
  slidesPerView: 3,
  spaceBetween: 16,
  threshold: 10,
  navigation: {
    nextEl: '.clients-slider .swiper-button-next',
    prevEl: '.clients-slider .swiper-button-prev'
  },
  breakpoints: {
    1215: {
      slidesPerView: 2,
    },
    384: {
      slidesPerView: 1,
    }
  }
});

const swiperHistoryDates = new Swiper('.history-slider-dates .swiper-container', {
  loop: false,
  speed: 500,
  threshold: 10,
  slidesPerView: 6,
  spaceBetween: 16,
  freeMode: true,
  watchSlidesVisibility: true,
  watchSlidesProgress: true,

  breakpoints: {
    1215: {
      slidesPerView: 5,
    },
    1006: {
      slidesPerView: 4,
    },
    384: {
      slidesPerView: 3,
    }
  }
});

const swiperHistoryText = new Swiper('.history-slider-info .swiper-container', {
  loop: false,
  speed: 500,
  threshold: 10,
  effect: 'fade',
  fadeEffect: {
    crossFade: true
  },
  navigation: {
    nextEl: '.history-slider-info .swiper-button-next',
    prevEl: '.history-slider-info .swiper-button-prev'
  },
  thumbs: {
    swiper: swiperHistoryDates
  }
});

const swiperHistoryHeadings = new Swiper('.history-headings-slider', {
  loop: false,
  speed: 500,
  threshold: 10,
  effect: 'fade',
  fadeEffect: {
    crossFade: true
  }
});

if ($('.history-headings-slider').length) {
  swiperHistoryText.controller.control = swiperHistoryHeadings;
  swiperHistoryHeadings.controller.control = swiperHistoryText;
}

const swiperAboutFactory1 = new Swiper('.about-factory-slider--first .swiper-container', {
  loop: true,
  speed: 500,
  threshold: 10,
  effect: 'fade',
  fadeEffect: {
    crossFade: true
  },
  navigation: {
    nextEl: '.about-factory-slider--first .swiper-button-next',
    prevEl: '.about-factory-slider--first .swiper-button-prev'
  }
});

const swiperAboutFactory1Texts = new Swiper('.about-factory__text-slider1', {
  loop: true,
  speed: 500,
  threshold: 10,
  effect: 'fade',
  fadeEffect: {
    crossFade: true
  }
});

if ($('.about-factory__text-slider1').length) {
  swiperAboutFactory1.controller.control = swiperAboutFactory1Texts;
  swiperAboutFactory1Texts.controller.control = swiperAboutFactory1;
}

const swiperAboutFactory2 = new Swiper('.about-factory-slider--second .swiper-container', {
  loop: true,
  speed: 500,
  threshold: 10,
  effect: 'fade',
  fadeEffect: {
    crossFade: true
  },
  navigation: {
    nextEl: '.about-factory-slider--second .swiper-button-next',
    prevEl: '.about-factory-slider--second .swiper-button-prev'
  }
});

const swiperAboutFactory2Texts = new Swiper('.about-factory__text-slider2', {
  loop: true,
  speed: 500,
  threshold: 10,
  effect: 'fade',
  fadeEffect: {
    crossFade: true
  }
});

if ($('.about-factory__text-slider2').length) {
  swiperAboutFactory2.controller.control = swiperAboutFactory2Texts;
  swiperAboutFactory2Texts.controller.control = swiperAboutFactory2;
}



const sliders = [];
$('.section-tabs-slider > .swiper-wrapper > .swiper-slide').each(function() {
  const docsSlider = $(this).find('.docs-slider .swiper-container');
  const namesSlider = $(this).find('.docs-names-slider .swiper-container');
  const prevButton =  $(this).find('.swiper-button-prev');
  const nextButton =  $(this).find('.swiper-button-next');
  const swiperDocs = new Swiper($(docsSlider)[0], {
    loop: false,
    speed: 500,
    parallax: true,
    threshold: 10,
    slidesPerView: 2,
    centeredSlides: true,
    nested: true,
    navigation: {
      nextEl: $(nextButton)[0],
      prevEl: $(prevButton)[0]
    },
    breakpoints: {
      1710: {
        slidesPerView: 1.8,
        centeredSlides: true,
      },
      757: {
        slidesPerView: 1,
        centeredSlides: false,
      }
    }
  });
  const swiperDocsNames = new Swiper($(namesSlider)[0], {
    loop: false,
    speed: 500,
    threshold: 10,
    slidesPerView: 1,
    effect: 'fade',
    nested: true,
    fadeEffect: {
      crossFade: true
    }
  });
  
  if ($('.docs-slider').length) {
    swiperDocs.controller.control = swiperDocsNames;
    swiperDocsNames.controller.control = swiperDocs;
  }

  sliders.push({
    swiperDocs,
    swiperDocsNames
  })
});

const swiperSectionTabs = new Swiper('.section-tabs-slider', {
  init: false,
  loop: false,
  speed: 500,
  threshold: 10,
  slidesPerView: 1,
  effect: 'fade',
  touchRatio: 0,
  fadeEffect: {
    crossFade: true
  }
});

function updateSliders(index) {
  sliders[index].swiperDocs.update()
  sliders[index].swiperDocsNames.update()

  setTimeout(() => {
    sliders[index].swiperDocs.update()
    sliders[index].swiperDocsNames.update()
  }, 100)
}

if ($('.section-tabs-slider').length) {
  swiperSectionTabs.on('init', function () {
    updateSliders(0);
  });
  
  swiperSectionTabs.on('slideChange', function (e) {
    const index = swiperSectionTabs.activeIndex;
    updateSliders(index);
  });
  
  swiperSectionTabs.init();
}

$('.section-tabs__link--control').on('click', function(e) {
  e.preventDefault();

  $('.section-tabs__link--control').removeClass('is-active');
  $(this).addClass('is-active');

  const index = $(this).parents('li').index();

  swiperSectionTabs.slideTo(index, 500, () => {});
});


let timeout;

if ($('.docs-sliders').length) {
  $(window).resize(() => {
    clearTimeout(timeout);
    const index = $('.section-tabs__link.is-active').parents('li').index()
    timeout = setTimeout(() => {
      updateSliders(index);
    }, 100);
  })
}

const swiperCatalogThumbs = new Swiper('.catalog-links-slider--thumbs .swiper-container', {
  loop: false,
  speed: 500,
  threshold: 10,
  slidesPerView: 6,
  spaceBetween: 16,
  watchSlidesVisibility: true,
  watchSlidesProgress: true,
  initialSlide: $('.catalog-menu-link.is-active').index() + 1,
  navigation: {
    nextEl: '.catalog-links-slider--thumbs .swiper-button-next',
    prevEl: '.catalog-links-slider--thumbs .swiper-button-prev'
  },
  breakpoints: {
    1850: {
      slidesPerView: 5,
    },
    1650: {
      slidesPerView: 4,
    },
    1450: {
      slidesPerView: 3,
    },
    1050: {
      slidesPerView: 2,
    },
    600: {
      slidesPerView: 1,
    }
  }
});

const swiperCatalogThumbs2 = new Swiper('.catalog-links-slider:not(.catalog-links-slider--thumbs) .swiper-container', {
  loop: false,
  speed: 500,
  threshold: 10,
  slidesPerView: 6,
  spaceBetween: 16,
  watchSlidesVisibility: true,
  watchSlidesProgress: true,
  initialSlide: $('.catalog-menu-link.is-active').index() + 1,
  navigation: {
    nextEl: '.catalog-links-slider .swiper-button-next',
    prevEl: '.catalog-links-slider .swiper-button-prev'
  },
  breakpoints: {
    1850: {
      slidesPerView: 5,
    },
    1650: {
      slidesPerView: 4,
    },
    1450: {
      slidesPerView: 3,
    },
    1050: {
      slidesPerView: 2,
    },
    600: {
      slidesPerView: 1,
    }
  }
});

const swiperCatalogImages = new Swiper('.catalog-image-slider', {
  loop: false,
  speed: 500,
  parallax: true,
  threshold: 10,
  slidesPerView: 2,
  centeredSlides: true,
  thumbs: {
    swiper: swiperCatalogThumbs
  },
  breakpoints: {
    1710: {
      slidesPerView: 1.9,
      centeredSlides: true,
    },
    757: {
      slidesPerView: 1,
      centeredSlides: false,
    }
  }
});

const swiperCatalogDescr = new Swiper('.catalog-text-slider', {
  loop: false,
  speed: 500,
  threshold: 10,
  slidesPerView: 1,
  effect: 'fade',
  fadeEffect: {
    crossFade: true
  }
});

if ($('.catalog-image-slider').length) {
  swiperCatalogImages.controller.control = swiperCatalogDescr;
  swiperCatalogDescr.controller.control = swiperCatalogImages;
}

const swiperComparison = new Swiper('.comparison-slider .swiper-container', {
  loop: false,
  speed: 1000,
  threshold: 10,
  slidesPerView: 1,
  effect: 'fade',
  fadeEffect: {
    crossFade: true
  },
  navigation: {
    nextEl: '.comparison-slider .swiper-button-next',
    prevEl: '.comparison-slider .swiper-button-prev'
  },
});
