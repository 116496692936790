// import Rellax from 'rellax'
import './SmoothScroll'
import skrollr from 'skrollr'

if(!(/Android|iPhone|iPad|iPod|BlackBerry|Windows Phone/i).test(navigator.userAgent || navigator.vendor || window.opera)){
  let s = null;
  if ($(window).outerWidth() >= 1005) {
    s = skrollr.init({
      smoothScrolling: false,
      forceHeight: false
    });
  }

  $(window).resize(function() {
    if ($(window).outerWidth() < 1005) {
      if (s) {
        skrollr.init().destroy();
        s = null
      }
    } else {
      if (!s) {
        s = skrollr.init({
          smoothScrolling: false,
          forceHeight: false
        });
      }
    }
  });
}

// if ($(window).innerWidth() > 755) {
//   var rellax1 = new Rellax( '.rellax:not(.rellax--top)' , {
//     center: true
//   });

//   var rellax2 = new Rellax( '.rellax--top' , {
//   });
// }
