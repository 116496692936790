import '@fancyapps/fancybox'
import PerfectScrollbar from 'perfect-scrollbar';

let ps;
if ($('.catalog-item-popup__scroll').length) {
  ps = new PerfectScrollbar('.catalog-item-popup__scroll', {
    maxScrollbarLength: 14,
  });
}


$('.js-openneeds').on('click', function(e) {
  e.preventDefault();

  $('.needs-popup').fadeToggle(300);
});

$('.needs-popup__close').on('click', () => {
  $('.needs-popup').fadeOut(300);
})


$('.catalog-item-popup__close').on('click', function(e) {
  e.preventDefault();

  $(this).parent().fadeOut(300);
});


$('.js-open-info').on('click', function(e) {
  e.preventDefault();

  const href = $(this).attr('href');

  $(href).fadeIn(300);

  ps.update();
})
